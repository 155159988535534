<template>
  <div>
    <!-- 
    <div class="text-right">
      <div>
        <b-button type="reject" variant="light" size="btn-sm" class="mr-2"
          >{{ $t("BUTTON.REJECT") }}
        </b-button>
        <b-button type="approve" variant="primary" size="btn-sm"
          >{{ $t("ENTITLEMENT.APPROVE") }}
        </b-button>
      </div>
    </div> 
-->
    <b-table
      ref="application-pending-page-dt"
      id="application-pending-page-dt"
      responsive="sm"
      :fields="fields"
      :items="getCompanyLeaveApplication"
      table-class="table-head-custom table-vertical-center mt-5"
    >
      <!-- A custom formatted header cell for field 'name' -->
      <template v-slot:head(index)="data">
        <span class="text-dark-75">{{ data.label.toUpperCase() }}</span>
      </template>

      <!-- A virtual column -->
      <template v-slot:cell(index)="">
        <label class="form-checkbox">
          <input type="checkbox" v-model="selectAll" @click="select" />
          <i class="form-icon"></i>
        </label>
      </template>

      <template v-slot:cell(name)="data">
        <div class="d-flex flex-column">
          <span class="font-weight-bolder text-h6">
            {{ data.item.name }}
          </span>
        </div>
      </template>

      <template v-slot:cell(actions)="data">
        <div class="d-flex flex-row justify-content-left">
          <!-- begin::: edit-details button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-on:click="showLeavePending(data)"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Write.svg" />
            </span>
          </button>
          <!-- end::: edit-details button -->

          <!-- begin::: edit-employee button -->
          <button
            class="btn btn-icon btn-light btn-hover-primary btn-sm mr-3"
            v-if="getSuperAdminPermission"
          >
            <span class="svg-icon svg-icon-primary menu-icon">
              <inline-svg src="/media/svg/icons/Communication/Flag.svg" />
            </span>
          </button>
          <!-- end::: edit-employee button -->

          <button
            class="btn btn-icon btn-light btn-hover-danger btn-sm mr-3"
            v-on:click="deleteEmployeeDetails"
          >
            <span class="svg-icon svg-icon-gray menu-icon">
              <inline-svg src="/media/svg/icons/General/Trash.svg" />
            </span>
          </button>
          <!-- end::: delete-employee button -->
        </div>
      </template>

      <template #table-busy>
        <AppLoader #table-busy></AppLoader>
      </template>
    </b-table>

    <div class="d-flex justify-content-end" v-if="items">
      <b-pagination
        pills
        v-model="items.current_page"
        :total-rows="items.total"
        :per-page="items.per_page"
        aria-controls="application-pending-page-dt"
        @change="handlePageChange"
      ></b-pagination>
    </div>
    <LeavePageApplicationPendingModal></LeavePageApplicationPendingModal>
  </div>
</template>

<script>
import { alert } from "sweetalert2";
import { mapState, mapGetters } from "vuex";
import AppLoader from "@/modules/core/components/loaders/AppLoader";
import swal from "sweetalert";
import LeavePageApplicationPendingModal from "@/modules/company/components/leave/application/LeavePageApplicationPendingModal";
export default {
  name: "LeavePageApplicationTable",
  props: ["reload"],
  components: {
    LeavePageApplicationPendingModal,
    AppLoader,
  },
  mounted() {},
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      fields: [
        // Mapped column which contains the name of the application approval
        { key: "index", label: "#" },
        { key: "employee", label: this.$t("EMPLOYEE.HEADER") },
        { key: "type", label: this.$t("LEAVE.TYPE") },
        { key: "from", label: this.$t("EDUCATION.FROM") },
        { key: "to", label: this.$t("EDUCATION.TO") },
        { key: "day", label: this.$t("HOLIDAY.DAY") },
        { key: "reason", label: this.$t("ENTITLEMENT.REASON") },
        { key: "status", label: this.$t("JOB.STATUS") },
        { key: "actions", label: this.$t("MENU.ACTIONS") },
      ],
    };
  },
  methods: {
    handlePageChange(value) {
      console.log(value);
      this.$store
        .dispatch("getDepartment", {
          page_number: value,
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
    showLeavePending(row) {
      console.log("Selected Leave ", row);
      this.$bvModal.show("leave-page-pending-modal");
      this.$store.dispatch("assignEmployeeId", row.item.id).then(() => {
        console.log("Done setting employee Id");
      });
    },
    deleteEmployeeDetails() {
      swal({
        title: this.$t("ALERT.SURE"),
        text: this.$t("ALERT.WARNING"),
        icon: "warning",
        showCancelButton: true,
        dangerMode: true,
        buttons: [this.$t("BUTTON.CANCEL"), this.$t("BUTTON.OKAY")],
        closeOnConfirm: false,
        closeOnCancel: false,
      }).then((willDelete) => {
        if (willDelete) {
          swal(this.$t("ALERT.CONFIRM"), {
            icon: "success",
          });
        }
      });
    },

    getDepartment() {
      return this.$store
        .dispatch("getDepartment")
        .then((response) => {
          console.log("Table has", response.data.data);
          return response.data.data;
        })
        .catch(() => {
          alert({
            title: "Failed",
          });
        });
    },
  },

  computed: {
    ...mapState({
      items: (state) => state.CompanyModule.department.list.items,
    }),
    ...mapGetters(["getCompanyLeaveApplication", "getSuperAdminPermission"]),
  },
  watch: {
    reload() {
      console.log("Reload Table!");
      this.$refs["department-list-page-dt"].refresh();
    },
  },
};
</script>

<style scoped></style>
