<template>
  <b-card>
    <!-- begin: header Content     -->
    <div class="d-flex justify-content-between flex-wrap">
      <div class="flex-row">
        <h3 class="card-label font-weight-bolder text-dark">
          {{ $t("LEAVE.APPLICATION") }}
        </h3>
      </div>
    </div>
    <b-button
      variant="primary"
      class="font-weight-bolder"
      v-on:click="synchronize"
      ><span class="svg-icon menu-icon">
        <inline-svg src="/media/svg/icons/Devices/Generator.svg" />
      </span>
      {{ $t("DEVICEEMPLOYEE.SYNC") }}
    </b-button>
    <div class="ml-auto" v-if="currentTab === 1">
      <b-button
        class="font-weight-bolder btn-hover-primary-dark"
        variant="primary"
        v-on:click="register"
      >
        <span class="svg-icon">
          <inline-svg src="/media/svg/icons/General/Save.svg" /> </span
        >{{ $t("DEVICEEMPLOYEE.SAVE") }}</b-button
      >
    </div>
    <div class="d-flex my-5 justify-content-center">
      <b-alert
        fade
        :show="countdown"
        :variant="variant"
        @dismiss-count-down="countDownChanged"
        ref="facial-device-table-alert"
        dismissible
        >Success</b-alert
      >
    </div>
    <b-tabs
      pills
      class="mt-5"
      nav-class="nav-light-primary"
      v-model="currentTab"
    >
      <b-tab :title="$t('ECOMMERCE.COMMON.ALL')" lazy>
        <div class="d-flex mt-2 flex-column">
          <LeavePageApplicationTable></LeavePageApplicationTable>
        </div>
      </b-tab>

      <b-tab :title="$t('ECOMMERCE.COMMON.PENDING')" lazy>
        <div class="d-flex mt-2 flex-column">
          <LeavePageApplicationPending></LeavePageApplicationPending>
        </div>
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import LeavePageApplicationTable from "@/modules/company/components/leave/application/LeavePageApplicationTable";
import LeavePageApplicationPending from "@/modules/company/components/leave/application/LeavePageApplicationPending";
export default {
  name: "LeavePageApplication",
  components: { LeavePageApplicationTable, LeavePageApplicationPending },
  data() {
    return {
      currentTab: 1,
      countdown: 0,
      busy: false,
      variant: "success",
    };
  },
  countDownChanged(dismissCountDown) {
    this.countdown = dismissCountDown;
  },
};
</script>

<style scoped></style>
